$(document).on("click", "html", function(e) {
  if (!$(e.target).closest("span").hasClass("dismissable-popup") && !$(e.target).closest(".popover").length) {
    $(".dismissable-popup").popover("hide");
  }
});

$(document).ready(function() {

  $('[data-toggle="tooltip"]').tooltip();
  $('[data-toggle="popover"]').popover({html: true});

  var ua = window.navigator.userAgent;
  var edge = ua.indexOf('Edge/');
  if (edge > 0) {
    $("input[type=number]").on("keydown", function(e) {
      if(e.keyCode === 188) { 
        this.value += ".";
        e.preventDefault();
      }
    });
  }

});

$(document).on("click", ".clickable-table-row", function(e) {
  console.log(e.target.tagName);
  if (e.target.tagName != 'A') window.document.location = $(this).data("href");
});