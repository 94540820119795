// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap")
require("chartkick")
require("chart.js")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// stylesheets
require("../stylesheets/application.scss")
require("../stylesheets/callout.scss")
require("../stylesheets/main_styles.scss")
require("../stylesheets/flags.css")
require("../stylesheets/checklists.scss")
require("../stylesheets/users.scss")
require("../stylesheets/tree_view.scss")

import $ from 'jquery';
global.$ = jQuery;

//require("controllers/questions")
import I18n from "i18n-js";
global.I18n = I18n;

import "./custom";
import "./checklist_steps";
import "@fortawesome/fontawesome-free/js/all";

import flatpickr from "flatpickr";
import {Czech} from "flatpickr/dist/l10n/cs.js"
import {Poland} from "flatpickr/dist/l10n/pl.js"

require("easy-autocomplete");

import 'select2'
import 'select2/dist/css/select2.css'
import "select2-bootstrap-theme/dist/select2-bootstrap.css";